import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from 'react-icons/fa';


// const VERIFY_PAYMENT = gql``;

function PaymentRedirectPage() {
  // const [transactionStatus, setTransactionStatus] = useState(null);
  // const [transactionId, setTransactionId] = useState(null);
  // const [verifyPayment, { loading, data }] = useMutation(VERIFY_PAYMENT);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const resultIndicator = urlParams.get("resultIndicator");
  //   const sessionVersion = urlParams.get("sessionVersion");

  //   setTransactionStatus(resultIndicator);
  //   setTransactionId(sessionVersion);
  //   verifyPayment({
  //     variables: { resultIndicator, sessionVersion },
  //     onCompleted: (data) => {
  //       console.log(data.verifyPayment);
  //     },
  //   });
  // }, []);

  return (
    <main className="flex items-center justify-center h-screen">
      <section className="text-center">
      <FaCheckCircle className="text-green-500 w-16 h-16 mx-auto mb-4" />
        <h1 className="text-3xl font-semibold mb-4">Payment Successful!</h1>
        <p className="text-lg">Thank you for your payment. Your transaction was successful.</p>
        {/* <h1 className="text-3xl mb-3">Payment status: {transactionStatus}</h1>
        <p>Transaction ID: {transactionId}</p>
        <div>
          {data && (
            <div>
              <p>Your booking payment has been received</p>
              <p>Here are the details of payment</p>
              <p>{data.verifyPayment.booking.accommodation.name}</p>
            </div>
          )}
        </div> */}
      </section>

    </main>
  );
}

export default PaymentRedirectPage;
