import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = React.lazy(() => import("./pages/Home.jsx"));
const NotFound = React.lazy(() => import("./pages/NotFound.js"));
const PaymentForm = React.lazy(() => import("./pages/PaymentForm.js"));
import PaymentRedirectPage from "./pages/paymentSuccess.js";

const App = (props) => {
  return (
    <div className="flex flex-col min-h-screen">
      <ToastContainer />
      <Routes>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/" element={<PaymentForm />} />
        <Route path="/payment/check" element={<PaymentRedirectPage />} />
        {/* <Route path="/" element={<Home {...props} />} /> */}
      </Routes>
    </div>
  );
};

export default App;
